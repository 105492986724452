import React, { Fragment } from "react";

import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { ACCESS_TOKEN } from '../constants';

//screens
import Home2 from "../pages/HomePage";
import CreateChannel from "../pages/channels/CreateChannelPage";
import { AssistirMaisTarde } from "../screens/Main/AssistirMaisTarde";
import Signin from "../screens/Main/Auth/Signin";
import Signup from "../screens/Main/Auth/Signup";
import CategoryView from "../screens/Main/CategoryView";
import { ChannelOptions } from "../screens/Main/ChannelOptions";
import { Editorial } from "../screens/Main/Editorial";
import Episode from "../screens/Main/Episode";
import { ExternalFeed } from "../screens/Main/ExternalFeed";
import { Favoritos } from "../screens/Main/Favoritos";
import Home from "../screens/Main/Home";
import { Later } from "../screens/Main/Later";
import { Premium } from "../screens/Main/Premium";
import { Registrations } from "../screens/Main/Registrations";
import { RespLanding } from "../screens/Main/RespLanding";
import { RespLandingNestle } from "../screens/Main/RespLandingNestle";
import Search from "../screens/Main/Search";
import { TagView } from "../screens/Main/TagView";
import Vertical from "../screens/Main/Vertical";
import { VerticalContent } from "../screens/Main/VerticalContent";

import { AppSejaPremium } from "../screens/Main/AppSejaPremium";

import { Message } from "../screens/Main/Message";

import PlaylistDetails from "../screens/Main/Playlists/details";

import Channels from "../pages/channels";
import ChannelAdmin from "../pages/channels/admin";
import { MyChannel } from "../screens/Main/MyChannel";

import Configurations from "../screens/Main/Configurations";
import Playlist from "../screens/Main/Playlists";
import { ProfileOptions } from "../screens/Main/ProfileOptions";

import { EmAlta } from "../screens/Main/EmAlta";
import { NewEpisode } from "../screens/Main/NewEpisode";
import { PlanosAssinatura } from "../screens/Main/PlanosAssinatura";
import TermosPrivacidade from "../screens/Main/Terms";



import DownloadApp from "../components/DownloadApp";
import RespChild from "../screens/Main/RespLanding/RespChild";
const Routes = (props) => {
  return (
    <BrowserRouter >
      <Fragment>
        <DownloadApp />
        <Switch>
          <Route exact path="/embed">
            <Redirect push to={"/embed/index.html"} />
          </Route>
          <Route exact path="/" component={Home} />
          <Route exact path="/signin" component={Signin} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/v" component={Signup} />
          <Route exact path="/v/:codigo" component={Signup} />

          <Route exact path="/home" component={Home2} />
          <Route exact path="/playlists" component={Playlist} />

          <Route exact path="/cadastro" component={Home} />


          {!localStorage.UserAccount ? <Route exact path="/playlist/favorites" component={Message} /> : <Route exact path="/playlist/favorites" component={Favoritos} />}
          <Route exact path="/playlists/:id" component={PlaylistDetails} />

          {!localStorage.UserAccount ? <Route exact path="/playlist/later" component={Message} /> : <Route exact path="/playlist/later" component={Later} />}

          <Route exact path="/search" component={Search} />
          <Route exact path="/search/:code" component={Search} />
          {!localStorage.length && !localStorage.getItem(ACCESS_TOKEN) ? <Redirect to="/#signup" /> : <Route exact path="/channels/create" component={CreateChannel} />}
          {!localStorage.length && !localStorage.getItem(ACCESS_TOKEN) ? <Redirect to="/#signup" /> : <Route exact path="/channels/:code/admin" component={ChannelAdmin} />}
          <Route exact path="/channels/:code" component={Channels} />


          <Route exact path="/channel/:id/episode/new" component={NewEpisode} />

          <Route exact path="/episodes/:id" render={(params) => {
            window.scrollTo(0, 0);
            return <Episode {...params} />
          }} />


          <Route exact path="/planos" component={PlanosAssinatura} />

          <Route exact path="/verticals" component={Vertical} />
          <Route exact path="/verticals/:code" component={Vertical} />
          <Route exact path="/externalfeed/:id" component={ExternalFeed} />

          <Route exact path="/configuration" component={Configurations} />
          <Route exact path="/editorial/:id" component={Editorial} />
          {/* <Route exact path="/premium" component={Premium} /> */}
          <Route exact path="/originais" component={Premium} />
          <Route exact path="/em-alta" component={EmAlta} />
          <Route exact path="/verticalcontent/:id" component={VerticalContent} />
          {!localStorage.UserAccount ? <Route exact path="/channeloption/:id" component={Message} /> : <Route exact path="/channeloption/:id" component={ChannelOptions} />}
          {!localStorage.UserAccount ? <Route exact path="/registrations" component={Message} /> : <Route exact path="/registrations" component={Registrations} />}


          <Route exact path="/my-channels" component={MyChannel} />
          <Route exact path="/resplanding/" component={RespLanding} />
          <Route exact path="/resplandingnestle/" component={RespLandingNestle} />

          <Route exact path="/register/" component={RespChild} />
          <Route exact path="/appsusc/" component={AppSejaPremium} />

          <Route
            exact
            path="/category/:main_category_id"
            component={CategoryView}
          />
          <Route exact path="/account/" component={ProfileOptions} />
          {/* <Route exact path="/dashboard/" component={ProfileOptions} /> */}

          <Route
            exact
            path="/assistir-mais-tarde"
            component={AssistirMaisTarde}
          />
          <Route exact path="/tagview/:code" component={TagView} />

          <Route path="/termos-privacidade" component={TermosPrivacidade} />

          <Route path='/loginVivo' component={() => {
            // window.location.href = 'https://wap.toing.com.br/landing/?origem=MADNET_PMOVIL_OLAPOC_BR&page=login&returnUrl=http://www.olapodcasts.com/resplanding/'; 
            window.location.href = 'https://lp.olapodcasts.com/lp/?origem=MADNET_PMOVIL_OLAPOC_BR&page=login&returnUrl=http://www.olapodcasts.com/resplanding/';
            return null;
          }} />
        </Switch>
      </Fragment>
    </BrowserRouter>
  )
};

export default Routes;
