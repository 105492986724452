import React, { Component } from "react";

import M from "materialize-css";
import { ChevronRight, Check } from "styled-icons/material";
import { Cog } from "styled-icons/fa-solid";

import moment, { duration } from "moment";
import "moment/locale/pt-br";


//component
import Layout from "../../hoc/Layout/Layout";
import { UploadBar } from "../../components/UploadBar";
import { AboutEpisode } from "../../components/AboutEpisode";

import ImgChannel from "../../../src/assets/img/torresmo-image.png";
import { compose } from 'recompose';
import { CHANNEL_QUERY_BY_ID } from '../../services/graphql/query';
import { CREATE_EPISODE_MUTATION } from '../../services/graphql/mutations'
import { EpisodesQuery } from "../ChannelOptionEpisodes/episodes.graphql";
import { Query, Mutation, graphql } from 'react-apollo';
import { sendMessageToUser } from '../../common';

//styles
import { Container } from "./ChannelNewEpisode.styles";

class ChannelNewEpisode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            porcent: null,
            audioEdit: true,
            sendInfo: false,
            loading: false,
            midia: false,

            tags: false,
            likes: true,
            comments: true,
            monetization: false,
            restricted: false,
            description: false,
            publish_date: null

        }
    }
    componentDidMount() {
        // console.log("PROPS INICIAL: ",this.props);
        var elems = document.querySelectorAll('.collapsible');
        var instances = M.Collapsible.init(elems, { accordion: false });
    }

    updateState = (state: Object) => {
        this.setState(state);
    }

    fazLoading = () => {
        this.setState({ porcent: 0 })
        this.setState({ audioEdit: false })

        //  this.setState({ porcent: 0 });
        //  var intervalId = setInterval(this.timer, 1000);
        //  this.setState({intervalId: intervalId});

    }
    // timer = () => {
    //     // setState method is used to update the state
    //     this.setState({ porcent: this.state.porcent + 3 });
    // }

    submitForm = async () => {
        let publishDate = null;
        // // console.log("Meu State",this.state);
        if (!this.state.title) {
            return sendMessageToUser('Você precisa preencher o Titulo do Episódio!');
        }
        if (!this.state.description) {
            return sendMessageToUser('Você precisa preencher a Descrição do Episódio!');
        }
        if (!this.state.midia) {
            return sendMessageToUser('Você precisa subir a midia!');
        }
        if (!this.state.publish_date || this.state.publish_date === null || this.state.publish_date === "") {
            publishDate = new Date().getTime()
        }
        if (this.state.publish_date) {
            publishDate = new Date(this.state.publish_date).getTime()
        }
        let body = {
            channel_id: this.props.channel_id,
            title: this.state.title,
            description: this.state.description,
            tags: this.state.tags,
            monetized: this.state.monetization,
            restricted: this.state.restricted,
            block_comments: !this.state.comments,
            block_likes: !this.state.likes,
            thumbnail: this.state.thumbnail,
            content: this.state.midia,
            publish_date: parseInt(publishDate / 1000),
        };
        if (this.state.restricted) {
            body.rating = "18";
        }

        let paramsEnviar = Object.entries(body).filter(([k, v]) => (v != null)).reduce((acc, [k, v]) => Object.assign(acc, { [k]: v }), {});
        //let newEp = await this.props.mutate({mutation: CREATE_EPISODE_MUTATION, variables: body});
        // console.log("Variaveis: ",paramsEnviar);

        this.setState({ loading: true })
        this.fazLoading();
        let that = this;
        this.props.mutate({
            mutation: CREATE_EPISODE_MUTATION,
            variables: paramsEnviar,
            context: {
                fetchOptions: {
                    onUploadProgress: (progress => {
                        that.setState({ porcent: (progress.loaded * 100 / progress.total).toString().split('.', 2)[0] });
                    }),
                }
            },
            refetchQueries: [{
                query: EpisodesQuery,
                variables:
                {
                    channel_id: this.props.channel_id,
                    thumbWidth: 300,
                    thumbHeight: 300
                }
            }]
        })
            .then((data) => {
                sendMessageToUser('Episodio criado com sucesso !')
                this.setState({ porcent: 100 });
                this.setState({ loading: false })
                this.setState({ sendInfo: true })
                // console.log("PROPS DE AGORA", this.props)
                // clearInterval(this.state.intervalId);
                let id_channel = this.props.data.channels.data[0].id;
                let props = this.props;
                window.setTimeout(function () {
                    return props.selectOption('episodes')
                }, 3000);
            })
            .catch((res) => {
                // console.log("Erro aqui", res);
                const errors = res.graphQLErrors.map(error => error.message);
                // console.log("Erro tratado", errors);
                if (!!this.state.loading) {
                    sendMessageToUser('Erro ao criar episodio')
                }
                this.setState({ loading: false })
                this.setState({ porcent: false });
                this.setState({ sendInfo: false })
                // clearInterval(this.state.intervalId);

            });

    }

    render() {

        return (

            <Container>

                <div className="content">
                    {!this.state.sendInfo ?
                        <ul className="collapsible collapsible-accordion">
                            <li className="contentSendFile active">
                                <div className="collapsible-header">
                                    Conteúdo do Episódio
                                    <ChevronRight className="status-dropdown" />
                                </div>

                                <div className="collapsible-body" >

                                    <UploadBar
                                        porcent={this.state.porcent}
                                        audioEdit={this.state.audioEdit}
                                        updateState={this.updateState}
                                    />
                                </div>
                            </li>
                            <li className={`contentInfoEpsode ${this.state.audioEdit ? "active" : this.state.porcent != null && "active"}`}>
                                <div className="collapsible-header">
                                    Sobre o Episódio
                                    <ChevronRight className="status-dropdown" />
                                </div>
                                {this.state.audioEdit == true ?
                                    <div className="collapsible-body">
                                        <AboutEpisode audioEdit={this.state.audioEdit} updateState={this.updateState} submitForm={this.submitForm} />
                                    </div>
                                    :
                                    this.state.porcent != null &&
                                    <div className="collapsible-body">

                                        <AboutEpisode audioEdit={this.state.audioEdit} />
                                    </div>
                                }
                            </li>
                        </ul>
                        :
                        <div className="contentSucess">
                            <div> <Check className="icon" /></div>
                            <span>
                                Seu episódio foi enviado com sucesso. Estamos processando seu áudio e logo logo estará disponível em nossa plataforma
                            </span>
                        </div>
                    }
                </div>
            </Container>
        )
    }
}
const propsToOptions = (props) => {

    return ({

        variables:
        {
            channel_id: props.channel_id,
            thumbWidth: 300,
            thumbHeight: 300,
            coverWidth: 800,
            coverHeight: 300,
        }
    }
    )
}

export default compose(graphql(CREATE_EPISODE_MUTATION), graphql(CHANNEL_QUERY_BY_ID, { options: propsToOptions }))(ChannelNewEpisode);
