import React, { Component } from "react";
//style
import { 
    Container,
    SearchIcon,
    WrapperSearch
 } from "./ChannelOptionEpisodes.style";

 import { connect } from 'react-redux';
 
import { Query, Mutation, graphql } from 'react-apollo';
import { compose } from 'recompose';
import { EpisodesQuery, DELETE_EPISODE } from './episodes.graphql';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { Button } from '../../elements/ButtonElement';
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

import { Link } from "react-router-dom";

import { Input } from "../../elements/Input";

import EpisodeCard from "../../components/EpisodeCardComponent";

const confirma = (id_episodio) => {
    return new Promise( (resolve, reject) => { confirmAlert({
      title: '',
      message: 'Tem certeza que deseja apagar o episodio ?',
      buttons: [
        {
          label: 'Confirmar',
          onClick: () => { 
              resolve(true);
          }
        },
        {
          label: 'Cancelar',
          onClick: () => { reject(false) }
        }
      ]
    }) }
    )
  }; 
 

const ChannelOptionEpisodes = (props) => {
  const [removeEpisode] = useMutation(DELETE_EPISODE);
  
  // console.log('props ===> ', props)
  
  return(
    <Container  >
      <div className="title">Gerenciar Episodios</div>
      <div className="row">
        <div className="col s12">
           <Button>
              <Link onClick={()=>props.selectOption('new-episodes')}><b>Gravar novo Episódio</b></Link>
          </Button>
        </div>
      </div>
      {/* <WrapperSearch>
         <SearchIcon className="search-icon" />
          <Input
            type="text"
            inputClasses="inputSearch"
            id="searchManager"
            name="searchManager"
            // onChange={e => this.handleInputChange(e)}
          />
           <Dropdown
            options={["ORDEM 1", "ORDEM 2"]}
            value="ORDENAR POR"
            menuClassName="drop"
            controlClassName="drop"
          />
         
      </WrapperSearch> */}
      <div className="eps">

          {
            !!props.data && !!props.data.episodes && !!props.data.episodes.data[0] ?
              props.data.episodes.data.map(
              (episode,indice) => 
              <div className="ep">
                  <EpisodeCard 
                      key={episode.id} 
                      horizontal 
                      scope="channel"
                      episode={episode} 
                  />
                  <div className="menu-gerente"> 
                      <Link  key={`ver_${indice}`} to={`/episodes/${episode.id}`} className="channel-link">Ver</Link>
                      {/*
                      <span>
                         <Link  key={`editar_${indice}`} to={`/episode/edit/${episode.id}`} className="channel-link">Editar</Link>
                      </span> 
                      */}
                      <span>
  
                        <Link key={`remove_${indice}`}
                              onClick={ async () => {
                                  let confirmacao = await confirma(episode.id);
                                  removeEpisode({variables: { episode_id: episode.id}})
                                  delete props.data.episodes.data[indice]
                              }
                          }>Remover</Link>
                          
                      </span>
                  </div>
              </div>
            )
            :
            <center>
              O canal ainda não possui nenhum <strong>episódio</strong> cadastrado.
            </center>
          }
      </div>

    </Container>
  )
}

const propsToOptions = (props) => {
  return (
    { variables: 
        { 
          channel_id: props.channel_id, 
          thumbWidth: 300, 
          thumbHeight: 300
        }
    }
)
}

export default compose(graphql(EpisodesQuery, { options: propsToOptions}))(ChannelOptionEpisodes);
