import React, { Component } from "react";

import { Link } from "react-router-dom";

//elements
import { Input } from "../../elements/Input";
import { Button } from "../../elements/CTA";
import { Camera } from "styled-icons/fa-solid";

import { sendMessageToUser } from "../../common";

//styles
import { Container } from "./AboutEpisode.styles";

import moment, { duration } from "moment";
import "moment/locale/pt-br";

const listType = [
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/tiff',
    'image/webp'
]

const contains = (arr, element) => {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i] === element) {
            return true;
        }
    }
    return false;
}

export default class AboutEpisode extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...props.state,
            title: "",
            tags: null,
            likes: true,
            comments: true,
            monetization: false,
            restricted: false,
            description: null,
            descriptionCount: 0,
            thumbnail: null,
            publish_date: null,
        };
    }

    selecionaThumb = (arquivo) => {
        // console.log("Thumbnail: ",arquivo.type);
        if (contains(listType, arquivo.type)) {
            this.setState({ thumbnail: arquivo });
            this.props.updateState({ thumbnail: arquivo });

            const fileToUpload = arquivo;
            const reader = new FileReader();
            const previewImg = document.getElementsByClassName("thumbepisode");
            // console.log("Preview IMG: ",previewImg);
            reader.onload = e => {
                // console.log(arquivo)
                let img = new Image;
                img.onload = () => {
                    return previewImg[0].style.backgroundImage = "url('" + e.target.result + "')"
                }
                img.src = reader.result;
            };
            reader.readAsDataURL(fileToUpload);
        } else {
            sendMessageToUser("O arquivo enviado deve ser uma imagem.");
        }
    }


    render() {
        // console.log("State ",this.state)
        return (
            <Container>
                <div className="adjustToCollon">
                    <div className="imageContent">

                        <div className="boxcontent">
                            <span className="thumbepisode"></span>
                            <div>
                                <input type="file" className="midiaupload" onChange={(e) => { this.selecionaThumb(e.target.files[0]) }} />
                                <Camera className="icon-item" />
                                Adicionar foto
                                <br />
                                do episódio
                            </div>
                        </div>
                    </div>
                    <div className="formContent">
                        <div className="inputContent">
                            <Input
                                maxLength={100}
                                type="text"
                                labelContent="Titulo do episódio *"
                                placeholder="Adicione o titulo para o episódio"
                                id="title"
                                name="title"
                                value={this.state.title}
                                onChange={e => { this.setState({ title: e.target.value }); this.props.updateState({ title: e.target.value }); }}
                            />
                            <Input
                                maxLength={100}
                                type="text"
                                labelContent="Tags do episódio"
                                placeholder="Adicione tags para o episódio"
                                id="tags"
                                name="tags"
                                value={this.state.tags}
                                onChange={e => { this.setState({ tags: e.target.value }); this.props.updateState({ tags: e.target.value }); }}
                            />
                            <Input
                                maxLength={100}
                                type="datetime-local"
                                labelContent="Data de publicação"
                                placeholder="dd/mm/aaaa"
                                id="date"
                                name="date"
                                value={this.state.publish_date}
                                onChange={e => { this.setState({ publish_date: e.target.value }); this.props.updateState({ publish_date: e.target.value }); }}
                            />
                        </div>
                        <div className="contentSwitch">
                            <div className="switch">
                                <label>
                                    <input type="checkbox" name="likes" defaultChecked onChange={e => { this.setState({ likes: e.target.checked }); this.props.updateState({ likes: e.target.checked });  }} />
                                    <span className="lever"></span>
                                    Curtidas
                                </label>
                            </div>
                            <div className="switch">
                                <label>
                                    <input type="checkbox" name="comments" defaultChecked onChange={e => { this.setState({ comments: e.target.checked }); this.props.updateState({ comments: e.target.checked }); }} />
                                    <span className="lever"></span>
                                    Comentários
                                </label>
                            </div>
                            <div className="switch">
                                <label>
                                    <input type="checkbox" name="monetization" onChange={e => { this.setState({ monetization: e.target.checked }); this.props.updateState({ monetization: e.target.checked }); }} />
                                    <span className="lever"></span>
                                    Monetização
                                </label>
                            </div>
                            <div className="switch">
                                <label>
                                    <input type="checkbox" name="restricted" onChange={e => { this.setState({ restricted: e.target.checked }); this.props.updateState({ restricted: e.target.checked }); }} />
                                    <span className="lever"></span>
                                    Não recomendado p/ menores de 18
                                </label>
                            </div>
                        </div>
                        <div className="textareaContent">
                            <div>
                                <label className="titleLabel" for="description">
                                    Descrição para o episódio *
                                </label>
                                <label className="titleLabel" for="description">  ({this.state.descriptionCount}/3000)</label>
                            </div>
                            <textarea
                                maxLength={3000}
                                name="description"
                                placeholder="Digite aqui uma breve descrição para o seu episódio."
                                value={this.state.description}
                                onChange={e => {
                                    this.setState({
                                        description: e.target.value,
                                        descriptionCount: e.target.value.length
                                    });
                                    this.props.updateState({ description: e.target.value });
                                }}
                            ></textarea>
                        </div>
                        <div className="contentBotton">
                            <Link to="#">Cancelar</Link>
                            <Button onClick={() => {
                                this.props.submitForm();
                            }}>Publicar</Button>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}
